import './Header.scss';

const Header = () => {
    return (
        <div className="logo-electrician">
            <a href="https://rvgelectric.ro/" title="Electrician autorizat Sibiu">
                <img className="max-width-70" src="img/rvg-logo-color.png" title="RVGElectric - Electrician Sibiu" alt="Electrician Sibiu - RVGElectric" />
            </a>
        </div>
    );
};

export default Header;