import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';

const HomePage = () => {
    const pozeGalerie: ReactImageGalleryItem[] = [
        {original: '/galerie/1.jpeg'},
        {original: '/galerie/2.jpeg'},
        {original: '/galerie/3.jpeg'},
        {original: '/galerie/4.jpeg'},
        {original: '/galerie/5.jpeg'},
        {original: '/galerie/6.jpeg'},
        {original: '/galerie/7.jpeg'},
        {original: '/galerie/8.jpeg'},
        {original: '/galerie/9.jpeg'},
        {original: '/galerie/10.jpeg'},
        {original: '/galerie/11.jpeg'},
        {original: '/galerie/12.jpeg'},
        {original: '/galerie/13.jpeg'},
        {original: '/galerie/14.jpeg'},
        {original: '/galerie/15.jpeg'},
        {original: '/galerie/16.jpeg'},
        {original: '/galerie/17.jpeg'},
        {original: '/galerie/18.jpeg'},
        {original: '/galerie/19.jpeg'},
        {original: '/galerie/20.jpeg'},
        {original: '/galerie/21.jpeg'},
        {original: '/galerie/22.jpeg'},
        {original: '/galerie/23.jpeg'},
        {original: '/galerie/24.jpeg'},
        {original: '/galerie/25.jpeg'}
    ];
    return (
        <>
            <h1>Electrician cu experiență, execut:</h1>
            <ul className="lista-servicii-instalatii-electrice">
                <li>instalații electrice</li>
                <li>rețele de date și TV</li>
                <li>automatizări porți și uși de garaj</li>
                <li>montaj panouri fotovoltaice</li>
                <li>control acces și interfoane</li>
                <li>montare sau înlocuire tablouri electrice</li>
                <li>montare sau înlocuire întrerupătoare, prize, hote, plite electrice, cuptoare, mașini de spălat vase/haine</li>
            </ul>

            <div className="contact-electrician-sibiu">
                <div className="tile-contact suna-electrician-sibiu-btn">
                    <div>
                        <img src="img/telefon-electrician.png" className="telefon-electrician" title="Suna la electrician" />
                    </div>
                    <div className="numere-telefon-electrician">
                        <span>Sună la numarul de telefon:</span>
                        <span><a href="tel:+40773342466">0773342466</a> sau <a href="tel:+40754044497">0754044497</a></span>
                    </div>
                </div>
                <div className="tile-contact whatsapp-electrician-sibiu-btn">
                    <div>
                        <img src="img/whatsapp-electrician.png" className="whatsapp-electrician" title="Trimite mesaj pe whatsapp la electrician" />
                    </div>
                    <a target="_blank" href="https://wa.me/+40773342466">Trimite mesaj<br />pe Whatsapp</a>
                </div>
            </div>

            <h2>Galerie foto lucrări</h2>
            <div className="galerie-foto">
                <ImageGallery items={pozeGalerie} />
            </div>
        </>
    );
};
export default HomePage;