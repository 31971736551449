import React, {useState} from 'react';

interface NaviContext {
    currentRoute: string,
    setCurrentRoute: Function
}

const initialNaviContext: NaviContext = {
  currentRoute: 'home',
  setCurrentRoute: (route: string) => {},
};

const NaviCtx = React.createContext(initialNaviContext);

export const NaviContextProvider = (props: any) => {
    const [currentRoute, setCurrentRoute] = useState<string>('home');
    
    return <NaviCtx.Provider value= {
        {
          currentRoute,
          setCurrentRoute
        }
    }>{props.children}</NaviCtx.Provider>
};

export default NaviCtx;