import {BrowserRouter, Route, Routes} from 'react-router-dom';
import HomePage from './Pages/Home/HomePage';
import NotFoundPage from './Pages/NotFound/NotFoundPage';
import Header from './Components/Header/Header';
import './App.scss';
import Footer from './Components/Footer/Footer';

const App = () => {
  const basePath = process.env.PUBLIC_URL;

  return (
    <>
      <Header/>
      <div id="paginaElectrician">
        <BrowserRouter basename={basePath}>
          <Routes>
            <Route path="/" element={<HomePage />}></Route>
            <Route path="*" element={<NotFoundPage />}></Route>
          </Routes>
        </BrowserRouter>
      </div>
      <Footer />
    </>
  );
};

export default App;