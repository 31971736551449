import './Footer.scss';

const Footer = () => {
    return (
        <>
            
        </>
    );
};

export default Footer;